// author:马云阳；description:众惠-公募-买入线下转账
<template>
  <section class="PublicFundProcess">
    <!-- <NavRightSide/> -->
    <div class="publicfundprocess_cont">
      <a @click="toGos" class="title_">
        <span class="iconfont">&#xe665;</span> 公募基金
      </a>
      <div class="side_cont">
        <ProgressBar :sideData="side" />
      </div>
      <div class="solids_"></div>
      <div class="card_">
        <div class="timer_ti">
          <div class="left_tis_timer">请在<span>{{Ns}}</span>前完成网银转账</div>
          <a @click="pdfurls&&pdfurls.length>0?toPdf(pdfurls):treatyerr()" class="rule_ti">转账规则</a>
        </div>
        <div class="sider_">
          <div class="card_si">
            <div class="name_">
              <div class="name">{{bankcardac.bankName}}</div>
              <div class="num_name">({{bankcardac.bankAccount}})</div>
            </div>
            <img style="margin-left:24px;" :src="bankcardac.logoUrl" class="img_s" alt="">
          </div>
          <div class="card_center">
            <div class="jg_">监管账户</div>
            <svg class="icon fon_" aria-hidden="true">
              <use xlink:href="#icon-mine-into"></use>
            </svg>
            <!-- <iconfont style="font-size:4em" type="#icon-mine-into" name></iconfont> -->
          </div>

          <div class="card_si">
            <img style="margin-right:24px;" src="@/static/img/myFund/ms_bank_logo.png" class="img_s" alt="">
            <div class="name_">
              <div class="name">民生银行</div>
              <div class="num_name">(6801)</div>
            </div>
          </div>
        </div>
        <div class="bankcard_">
          <div class="bank_box">
            <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i"
              class="banks_">
              <div class="top_ban">
                <img :src="item.logoUrl" alt="">
                <span class="bank_name">{{item.bankName}}</span>
              </div>
              <div class="cardnum_">{{item.bankAccount}}</div>
              <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
            </div>
          </div>
          <a @click.stop="replacebank" class="bank_type_btn">更换银行卡</a>
        </div>
        <div class="confirm_">
          <div class="title_confirm">
            <span class="letf_ti">核对买入基金</span><span class="rule_s">*请准确核对转账信息，否则将导致匹配失败</span>

          </div>
          <div class="table_confirm_">
            <div class="td_">
              <div class="l_ti">基金名称</div>
              <div class="r_cont">
                <span class="cont_">{{funddatas.fund.fundName}}({{funddatas.fund.fundCode}})</span>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">转账金额</div>
              <div class="r_cont">
                <input ref="o_aUrl1" class="cont_" type="text" :value="funddatas.num" readonly />
                <a @click="copynum" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">收款户名</div>
              <div class="r_cont">
                <input ref="o_aUrl2" class="cont_" type="text" value="众惠基金销售有限公司" readonly />
                <a @click="copysj" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">开户银行</div>
              <div class="r_cont">
                <span class="cont_">中国民生银行股份有限公司贵阳分行营业部</span>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">收款账号</div>
              <div class="r_cont">
                <input ref="o_aUrl3" class="cont_" type="text" value="698396801" readonly />
                <a @click="copyzh" class="copy_">复制</a>
              </div>
            </div>
            <div class="td_">
              <div class="l_ti">大额收款账号</div>
              <div class="r_cont">
                <input ref="o_aUrl3" class="cont_" type="text" value="305701000027" readonly />
                <a @click="copyzh" class="copy_">复制</a>
              </div>
            </div>
          </div>
        </div>
        <div class="inputs_">
                <div class="title_input">
                  <span class="l_ti">请输入交易密码:</span>
                  <a class="r_ti" @click="forgetPoss">忘记密码?</a>
                </div>
                <div class="inputs_boxs">
                  <input style="width:100%;height:100%;" :maxlength="6" v-model="passwords" placeholder="请输入六位交易密码" type="password">
                </div>
        </div>
        <div style="margin-top:30px;" class="file_ti">
         <span style="margin-right:6px;color:#CE9B63" class="iconfont">&#xe670;</span>
         <div class="file_txt_ti">为了您的资金安全，交易遵守 <span class="file_btn">同卡进出</span> </div>
        </div>
        <div class="files_">
          <input v-model="checkbool" style="width:10px;height:10px;margin-top: 3px;"
            type="checkbox">
          <div class="files_btns">
            阅读并同意<a @click="agree7&&agree7.length>0?toPdf(agree7):treatyerr()"  class="tobtn_">《众惠基金网银转账协议》</a>
            等内容
          </div>
        </div>
        <a @click="getbtns?getbtn():''" class="getbtn_">
          提交转账信息
        </a>
      </div>

    </div>
  </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import { getOfflineBankAcct, subscribe,subscription } from "@/api/addfund";
import { protPage } from "@/api/other/index.js";
// import { annAndProt } from "@/api/commom.js";
export default {
  name: "BuyingFundOffline",
  components: {
    ProgressBar
  },
  data() {
    return {
      pdfurls: "",
      side: [
        {
          title: "填写买入信息",
          type: 1
        },
        {
          title: "核对线下转账信息",
          type: 2
        },
        {
          title: "交易结果",
          type: 0
        }
      ],
      play: [],
      cardindex: 0, //
      bankcard: {}, //默认银行卡
      bankcardac: {}, //选中银行卡切换
      bankcarddata: [], //提交银行卡账号
      bankcards: {},
      checkbool: false, //是否同意
      funddatas: {},
      userinfo: {},
      Ns: "--",
      passwords: null, //密码
      agree7: "",
      annAndProtlist: {
        annList: [], //公告
        protList: [] //协议
      }, //基金协议及公告
      getbtns: true
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push("/myFavors/accountManage/safetyCentre");
    },
    toGos() {
      this.$router.go(-1);
    },
    // annAndProtdata() {
    //   annAndProt({
    //     fundCode: this.funddatas.fund.fundCode,
    //     protLik: "6",
    //     protType: "2",
    //     status: "1"
    //   }).then(res => {
    //     this.annAndProtlist = res.data;
    //   });
    // },
    /** 获取产品协议 */
    getProtocol() {
      protPage({
        pageSize: 100,
        protType: "1",
        protLink: "7",
        pageNum: 1
      }).then(result => {
        let data = result.data.data || [];
        const arr = data.filter(v => v.protNameDesc.includes("转账"));
        this.agree7 = arr && arr[0] && arr[0].protUrl;
      });
    },
    getProtocolrule() {
      protPage({
        pageSize: 100,
        protType: "1",
        protLink: "9",
        pageNum: 1
      }).then(result => {
        let data = result.data.data || [];
        const arr = data.filter(v => v.protNameDesc.includes("转账规则"));
        this.pdfurls = arr && arr[0] && arr[0].protUrl;
      });
    },
    getnewdata(a) {
      let date = a;
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let newdates = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      let jjj = yy + "-" + mm + "-" + dd + " " + "15:00:00";
      let aaa = new Date(jjj);
      let bbb = new Date(newdates);
      // let dds = date.getDate() + 1;
      // this.Ns = yy + "-" + mm + "-" + dds + " " + "15:00:00";
      if (bbb >= aaa) {
        // this.txts = "明天15点(节假日顺延)";
        let dds = date.getDate() + 1;
        // let ees = date.getDate() + 3;
        this.Ns = yy + "-" + mm + "-" + dds + " " + "15:00:00";
        // this.Es = yy + "-" + mm + "-" + ees;
      } else {
        // this.txts = "15点";
        let dds = date.getDate();
        // let ees = date.getDate() + 2;
        this.Ns = yy + "-" + mm + "-" + dds + " " + "15:00:00";
        // this.Es = yy + "-" + mm + "-" + ees;
      }
    },
    topeges() {
      console.log("执行下一步");
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.bankcard = obj;
    },
    replacebank() {
      let n = this.bankcard;
      this.bankcards = this.bankcarddata[this.cardindex];
      n = { ...n, bankAccount: n.bankAccount.slice(-4) };
      this.bankcardac = n;
    },
    copysj() {
      let input = this.$refs.o_aUrl2;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    copyzh() {
      let input = this.$refs.o_aUrl3;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    copynum() {
      let input = this.$refs.o_aUrl1;
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    getOfflineBankAcctdata() {
      getOfflineBankAcct().then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          // this.play=res.data;
          let n = res.data && res.data[0];
          let an = JSON.stringify(n);
          let bn = JSON.parse(an);
          this.bankcardac = { ...bn, bankAccount: bn.bankAccount.slice(-4) };
          this.bankcards = res.data && res.data[0];
          this.bankcarddata = res.data;
          // let str3 = item.bankAccount;
          let datas = res.data;
          let a = JSON.stringify(datas);
          let b = JSON.parse(a);
          this.play = b.map(item => {
            let str = item.bankAccount.slice(0, 4);
            let str2 = item.bankAccount.slice(-4);
            item.bankAccount = str + " **** **** " + str2;
            return item;
          });
          // this.annAndProtdata();
          this.getProtocol();
          this.getProtocolrule();
        }
      });
    },
    getbtn() {
      if (!this.checkbool) {
        this.$message.error("请阅读并同意网银转账协议！");
      } else if (!this.passwords || this.passwords.length < 6) {
        this.$message.error("请正确输入密码！");
      } else {
        this.getbtns = false;
        let params = {
          fundCode: this.funddatas.fund.fundCode, //基金代码/产品代码
          balance: this.funddatas.num, //申请金额
          riskNotice: "1", //是否阅读风险告知书
          tradePassword: this.passwords, //交易密码
          channelSource: "1", //订单来源
          riskLevelSts:
            this.userinfo.riskLevel < this.funddatas.fund.ofundRisklevel
              ? "1"
              : this.userinfo.riskLevel == this.funddatas.fund.ofundRisklevel
                ? "3"
                : this.userinfo.riskLevel > this.funddatas.fund.ofundRisklevel
                  ? "2"
                  : "1", //客户与产品风险等级状态。1:低于；2：高于；3：等于
          tradeAcco: "698396801", //交易账号
          bankAccount: this.bankcards.bankAccount,
          payType: "3" //付款方式 1:惠金宝 2:快捷支付 3:线下转账
        };
        if(this.funddatas.newfund == 0){
          subscribe(params)
          .then(res => {
            if (res.code != 200) {
              this.$message.error(res.msg);
            } else {
              let date = new Date();
              let okey = {
                funddatas: this.funddatas,
                dates: date,
                bankcard: this.bankcards
              };
              this.$router.push({
                name: "BuyingFundOfflinefour",
                params: { okey }
              });
            }
          })
          .catch(e => {
            this.getbtns = true;
          });
        }else{
          subscription(params)
          .then(res => {
            if (res.code != 200) {
              this.$message.error(res.msg);
            } else {
              let date = new Date();
              let okey = {
                funddatas: this.funddatas,
                dates: date,
                bankcard: this.bankcards
              };
              this.$router.push({
                name: "BuyingFundOfflinefour",
                params: { okey }
              });
            }
          })
          .catch(e => {
            this.getbtns = true;
          });
        }
        
      }
    }
  },
  created() {
    this.funddatas = JSON.parse(this.$route.query.params);
    this.userinfo =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    this.getOfflineBankAcctdata();
    let a = new Date();
    this.getnewdata(a);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  color: #bdc0cb;
}

::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}
.icon {
  width: 2em;
  // height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.fon_ {
  font-size: 28px;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;

  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;

    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }

    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }

    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }

    .card_ {
      padding: 0px 40px;
      margin-top: 30px;

      .timer_ti {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_tis_timer {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
          font-weight: 700;
          color: #1f1f1f;

          span {
            color: #eabf96;
            margin: 0 4px;
          }
        }

        .rule_ti {
          font-size: 14px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #bdc0cb;
        }
      }

      .sider_ {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        .card_si {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 320px;
          height: 93px;
          border-radius: 12px;
          box-shadow: 0px 5px 10px 0px #edf1f7;

          .name_ {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .name {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
              font-weight: 700;
              color: #25293d;
            }

            .num_name {
              font-size: 14px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #bdc0cb;
              margin-top: 10px;
            }
          }

          .img_s {
            width: 45px;
            height: 45px;
          }
        }

        .card_center {
          width: 120px;
          height: 82px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #8691a8;
        }
      }

      .bankcard_ {
        margin-bottom: 30px;
        padding: 30px 24px 6px 24px;
        margin-top: 24px;
        background: #f6f5f3;

        .bank_box {
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          border-radius: 4px;

          .backs_ac {
            width: 208px !important;
            border: 1px solid #ce9b63;
            height: 54px !important;
            border-radius: 4px;
          }

          .banks_ {
            padding: 22px 20px;
            width: 210px;
            height: 56px;
            background: #fff;
            margin-right: 24px;
            margin-bottom: 24px;
            position: relative;
            border-radius: 4px;

            &:nth-child(3n + 3) {
              margin-right: 0px;
            }

            .right_true {
              position: absolute;
              right: -16.5px;
              top: -16.5px;
              width: 0px;
              height: 0px;
              border: 16px solid #f7d9b7;
              border-color: transparent transparent #eabf96 transparent;
              transform: rotate(45deg);

              // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
              span {
                color: #fff;
                position: absolute;
                transform: rotate(-35deg);
                top: -1px;
                right: -5px;
              }
            }

            .top_ban {
              display: flex;
              align-items: center;

              img {
                width: 22px;
                height: 22px;
                margin-right: 7px;
              }

              .bank_name {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
            }

            .cardnum_ {
              margin-top: 20px;
              font-size: 18px;
              font-family: Bahnschrift, Bahnschrift-Light;
              font-weight: 300;
              color: #1f1f1f;
            }
          }

          .addbank_ {
            width: 249px;
            height: 98px;
            border: 1px dashed #bdc0cb;
            border-radius: 4px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }

            .add_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #bdc0cb;
            }
          }
        }

        .bank_type_btn {
          width: 98px;
          height: 28px;
          display: inline-block;
          background: #deb87b;
          border-radius: 4px;
          text-align: center;
          color: #fff;
          line-height: 28px;
          margin-left: 86%;
          margin-bottom: 24px;
        }
      }

      .confirm_ {
        margin-top: 30px;

        .title_confirm {
          .letf_ti {
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: 700;
            color: #1f1f1f;
          }

          .rule_s {
            margin-left: 178px;
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #e45247;
          }
        }

        .table_confirm_ {
          margin-top: 24px;

          .td_ {
            display: flex;
            align-items: center;

            .l_ti {
              width: 120px;
              height: 47px;
              border-bottom: 1px solid #fff;
              background: #f3f4f6;
              line-height: 47px;
              font-size: 12px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
              font-weight: 300;
              color: #1f1f1f;
              text-indent: 2em;
            }

            .r_cont {
              width: 379px;
              height: 47px;
              padding-right: 20px;
              border-top: 1px solid #f3f4f6;
              border-right: 1px solid #f3f4f6;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:last-child {
                border-bottom: 1px solid #f3f4f6;
              }
              .cont_ {
                font-size: 12px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
                font-weight: 300;
                color: #1f1f1f;
                text-indent: 2em;
                width: 70%;
              }

              .copy_ {
                display: inline-block;
                background: #f6f5f3;
                width: 44px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
                font-weight: 300;
                text-align: center;
                color: #8691a8;
              }
            }
          }
        }
      }
      .inputs_ {
        width: 520px;
        margin-top: 40px;
        .title_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .l_ti {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #1f1f1f;
          }
          .r_ti {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #a5a5a5;
          }
        }
        .inputs_boxs {
          display: flex;
          align-items: center;
          width: 494px;
          height: 46px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          padding-left: 24px;
          margin-top: 14px;
        }
      }
      .file_ti {
        display: flex;
        align-items: center;

        .file_img_ti {
          width: 22px;
          height: 22px;
          margin-right: 3px;
        }

        .file_txt_ti {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #1f1f1f;

          .file_btn {
            color: #ce9b63;
          }
        }
      }

      .files_ {
        margin-top: 17px;
        display: flex;

        .files_btns {
          width: 500px;
          margin-left: 10px;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #1f1f1f;

          .tobtn_ {
            color: #ce9b63;
          }
        }
      }
      .getbtn_ {
        margin-top: 40px;
        margin-bottom: 30px;
        display: inline-block;
        width: 200px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        font-size: 16px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        color: #b88141;
      }
    }
  }
}
</style>